import { render, staticRenderFns } from "./searchMoreModal.vue?vue&type=template&id=487c8c5d"
import script from "./searchMoreModal.vue?vue&type=script&lang=js"
export * from "./searchMoreModal.vue?vue&type=script&lang=js"
import style0 from "./searchMoreModal.vue?vue&type=style&index=0&id=487c8c5d&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports