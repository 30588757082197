<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="6" class="my-1">
            <h2 class="text-primary"><b>Cancel Tickets (ใบงานทั้งหมด {{showNumberItem }} ใบงาน)</b></h2>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form inline class="d-flex justify-content-end" v-on:submit.prevent>
              <b-input-group class="input-group-merge" style="width: 50%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>

              <b-button variant="outline-primary" class="ml-1 shadow" v-b-modal.search-more-modal
                >Search more <feather-icon icon="FilterIcon" size="14"
              /></b-button>
            </b-form>
          </b-col>

          <!--START TABLE -->
          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow text-center"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading.ticket"  
              selectable
              select-mode="single"
              @row-selected="onSelectTicket"                 
            >
            <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(sla)="row">
                <center>
                  <feather-icon
                    v-if="row.item.sla == 'wsla'"
                    icon="CheckCircleIcon"
                    class="cursor-pointer text-success"
                  />
                  <feather-icon
                    v-else
                    icon="XCircleIcon"
                    class="cursor-pointer text-danger"
                  />
                </center>
              </template>

              <template #cell(openTicketDate)="row">
                {{ showDate(row.item.openTicketDate, "original") }}
              </template>  
              <template #cell(closeTicketDate)="row">
                {{ showDate(row.item.closeTicketDate, "original") }}
              </template>                            
            </b-table>
          </b-col>
          <!--END TABLE -->

          <b-col cols="6" md="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" md="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>

     
      </b-card>
    </b-col>
    <SearchMoreModal :getTicketLists="getTicketLists"/>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BCard,
  BForm,
  BSpinner,
  BTr,
  BTh,
} from "bootstrap-vue";

import moment  from "moment"
import Field from "./field";
import SearchMoreModal from "./components/modal/searchMoreModal"
import TicketService from "@/services/ticket";
import commaNumber from 'comma-number'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BCard,
    BForm,
    BSpinner,
    BTr,
    BTh,        
    SearchMoreModal
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],

      isLoading:{
        ticket:false,        
      }
    };
  },

  created(){
    this.getTicketLists()
  },
  computed: {
    ticketPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("TK005")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
   
    showNumberItem(){
      return commaNumber(this.items.length)
    }
  },
  methods: {
    async getTicketLists(queryString="") {
      try {
        this.isLoading.ticket = true;   
        const result = await TicketService.GetTicketCancelList({}, queryString);    
        this.items = result.data.data;
        this.totalRows = this.items.length;
        this.isLoading.ticket = false;
      } catch (error) { 
        console.log(error)     
        alert("Load data error.");
      }
    },

    onSelectTicket(item) {
      console.log("item selected", item);
      this.$router.push(`/ticket/edit/${item[0].ticketId}`);
    },

    showDate(date, type="") {
      if(!date){
        return ""
      }
      
      if (type == "date") {
        return moment(date).format("DD-MM-yyyy");
      }

      if(type=="original"){
        return moment(date).subtract(7, 'h').format("DD-MM-yyyy HH:mm:ss");
      }

      if(type=="subtract"){
        return moment(date).subtract(7, 'h')
      }

      return moment(date).format("DD-MM-yyyy HH:mm:ss");
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

  mounted() {
    this.totalRows = this.items.length;   
  },  
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
